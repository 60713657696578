import React from "react";
import { graphql } from "gatsby";

import Layout from "../../components/template/Layout";
import HomeIntro from "../../components/organism/HomePageComponent/HomeIntro";
import PageSection from "../../components/organism/HomePageComponent";
import { Card } from "../../components/molecule/Card";
import { useGlobalStateContext } from "../../Context/site";
import {
  CountryPagePropsType,
  lang,
  ContentShape,
} from "../../entities/queriesTypes";
import { CurrentView } from "../../entities/templates";
import translations from "../../entities/translations";
import PortableText from "../../components/sanityBlockContents/PortableText";
import Text from "../../components/atoms/Text";
import {
  buildImageFocalPoint,
  getCountryPageTranslation as gCPT,
  getCarouselView,
  formatMoney,
} from "../../utils";
import useSetGlobalState from "../../components/hooks/useSetGlobalState";
import Carousel from "../../components/molecule/Glidejs";
import { countrySubMenu } from "../../utils/subNavigation";

const IndexPage: React.FC<CountryPagePropsType> = (props) => {
  const pageID = translations.globals.subMenuTranslation;
  const pageHash = translations.countryMenuID;
  const buttonTransaltion = translations.globals.buttons;
  const { state } = useGlobalStateContext();
  const { data, pageContext } = props;
  const page = data && data.pageData;
  const locale = pageContext && pageContext.locale;
  useSetGlobalState({
    page,
    pageContext,
    locale,
    currentView: CurrentView.index,
    subNavigationItem: countrySubMenu,
  });
  const _regionTranslation = translations.CountryPages.find(
    (item) => item.menu === "Orte"
  )!;
  const _tripTranslation = translations.CountryPages.find(
    (item) => item.menu === "Reisen"
  )!;
  const _accomodationTranslation = translations.CountryPages.find(
    (item) => item.menu === "Unterkunfte"
  )!;
  const AccommodationLinks =
    translations.globals.subMenuAccommodationTranslation;
  const FROM =
    translations.globals.subMenuTripTranslation.Ab?.[state?.lang as lang];

  const renderPortableText = (_state: any) => {
    return (
      <PortableText
        content={
          _state?.siteConfig?.travelInfoCountryInfoGlobal?.[state?.lang as lang]
        }
        className=""
        template={{
          h2: (props: any) => <Text type="H2">{props.children}</Text>,
          normal: (props: any) => (
            <Text type="P" ptype="BASE" className="leading-7">
              {props.children}
            </Text>
          ),
          li: (props: any) => <li className="">{props.children}</li>,
          ul: (props: any) => (
            <ul className="list-inside list-disc">{props.children}</ul>
          ),
        }}
      />
    );
  };

  return (
    <Layout
      seoTitle={
        (page?.metadata?.page_title &&
          page?.metadata?.page_title[state?.lang as lang]) as string
      }
      meta={[
        {
          name: "description",
          content: page?.metadata?.page_description
            ? (page?.metadata?.page_description[state?.lang as lang] as string)
            : "",
        },
        {
          name: "keywords",
          content: page?.metadata?.page_keywords
            ? (page?.metadata?.page_keywords[state?.lang as lang] as string)
            : "",
        },
      ]}
    >
      <HomeIntro
        readMoreLink={`/${state?.lang}/country/${pageContext?.slug}/${gCPT({
          view: CurrentView.overview,
          lang: state?.lang as lang,
        })}#${pageHash?.Uberblick?.[state?.lang as lang]!}`}
        sanityContext={
          page?.countryIntroduction &&
          (page?.countryIntroduction[state?.lang as lang] as ContentShape[lang])
        }
        id={pageHash?.Uberblick?.[state?.lang as lang]!}
      />

      <PageSection
        readMoreLink={`/${state?.lang}/country/${pageContext?.slug}/${gCPT({
          view: CurrentView.highlights,
          lang: state?.lang as lang,
        })}#${pageHash?.Hohepunkte?.[state?.lang as lang]!}`}
        sanityContext={
          page?.highlightsIntroduction &&
          page?.highlightsIntroduction[state?.lang as lang]
        }
        id={pageHash?.Hohepunkte?.[state?.lang as lang]!}
        idTitle={pageID.Hohepunkte[state?.lang as lang]!}
        layerBackground="bg-white-smoke"
        title={state?.countryName!}
      >
        {page?.countryHighlights?.slice(0, 8).length ? (
          <Carousel
            initClass="Hohepunkte"
            perView={getCarouselView(page?.countryHighlights?.length)}
            carouselLength={page?.countryHighlights?.length}
          >
            {page?.countryHighlights?.map((item, i: number) => (
              <Card
                uniqueRef={item?.["_id"]!}
                key={i}
                _className="card-responsive glide__slide"
                imageAlt={
                  (item?.regionImage?.alt &&
                    item?.regionImage?.alt[state?.lang as lang]) as string
                }
                imageUrl={buildImageFocalPoint(item.regionImage?.image)}
                title={(item?.title && item?.title[state?.lang as lang]) || ""}
                ctaText={
                  buttonTransaltion.WEITERLESEN[state?.lang as lang] || ""
                }
                cardType="COUNTRY"
                cardSize={"SMALL"}
                ctaUrl={`/${state?.lang}/${
                  item?.parentCountry?.slug &&
                  item?.parentCountry?.slug[state?.lang as lang]?.current
                }/${_regionTranslation.slug[state?.lang as lang]}/${
                  item?.slug && item?.slug[state?.lang as lang]?.current
                }#${pageHash?.Hohepunkte?.[state?.lang as lang]!}`}
              />
            ))}
          </Carousel>
        ) : (
          <> </>
        )}
      </PageSection>

      <PageSection
        readMoreLink={`/${state?.lang}/country/${pageContext?.slug}/${gCPT({
          view: CurrentView.regions,
          lang: state?.lang as lang,
        })}#${pageHash?.Orte?.[state?.lang as lang]!}`}
        sanityContext={
          page?.regionIntroduction &&
          page.regionIntroduction[state?.lang as lang]
        }
        id={pageHash?.Orte?.[state?.lang as lang]!}
        idTitle={pageID.Orte[state?.lang as lang]!}
        title={state?.countryName!}
      >
        {page?.region?.length ? (
          <Carousel
            initClass={"Orte"}
            perView={getCarouselView(page?.region?.length)}
            carouselLength={page?.region?.length}
          >
            {page?.region?.slice(0, 8).map((item, i: number) => (
              <Card
                uniqueRef={item?.["_id"]!}
                key={i}
                _className="card-responsive glide__slide"
                imageAlt={
                  (item?.regionImage?.alt &&
                    item?.regionImage?.alt[state?.lang as lang]) ||
                  ""
                }
                imageUrl={buildImageFocalPoint(item.regionImage?.image)}
                title={(item?.title && item?.title[state?.lang as lang]) || ""}
                ctaText={
                  buttonTransaltion.WEITERLESEN[state?.lang as lang] || ""
                }
                cardType="REGION"
                ctaUrl={`/${state?.lang}/${
                  item?.parentCountry?.slug &&
                  item?.parentCountry?.slug[state?.lang as lang]?.current
                }/${_regionTranslation.slug[state?.lang as lang]}/${
                  item?.slug && item?.slug[state?.lang as lang]?.current
                }#`}
                bodyText={
                  (item?.cardTextShort &&
                    item.cardTextShort[state?.lang as lang]) ||
                  ""
                }
                cardSize={"SMALL"}
              />
            ))}
          </Carousel>
        ) : (
          <></>
        )}
      </PageSection>

      <PageSection
        readMoreLink={`/${state?.lang}/country/${pageContext?.slug}/${gCPT({
          view: CurrentView.trips,
          lang: state?.lang as lang,
        })}#${pageHash?.Reisen?.[state?.lang as lang]!}`}
        sanityContext={
          page?.tripIntroduction && page?.tripIntroduction[state?.lang as lang]
        }
        id={pageHash?.Reisen?.[state?.lang as lang]!}
        idTitle={pageID.Reisen[state?.lang as lang]!}
        title={state?.countryName!}
        layerBackground="bg-white-smoke"
      >
        {page?.trips?.length ? (
          <Carousel
            initClass={"Reisen"}
            perView={getCarouselView(page?.trips?.length)}
            carouselLength={page?.trips?.length}
          >
            {page?.trips?.slice(0, 8).map((item, i: number) => (
              <Card
                uniqueRef={item?.["_id"]!}
                key={i}
                _className="card-responsive glide__slide"
                imageAlt={
                  (item?.tripImage?.alt &&
                    item?.tripImage?.alt[state?.lang as lang]) ||
                  ""
                }
                imageUrl={buildImageFocalPoint(item.tripImage?.image)}
                title={(item?.title && item?.title[state?.lang as lang]) || ""}
                ctaText={
                  buttonTransaltion.WEITERLESEN[state?.lang as lang] || ""
                }
                cardType="PACKAGES"
                ctaUrl={`/${state?.lang}/${
                  item?.parentCountry?.slug &&
                  item?.parentCountry?.slug[state?.lang as lang]?.current
                }/${_tripTranslation.slug[state?.lang as lang]}/${
                  item?.slug && item?.slug[state?.lang as lang]?.current
                }`}
                bodyText={
                  (item?.cardTextShort &&
                    item.cardTextShort[state?.lang as lang]) ||
                  ""
                }
                duration={
                  (item?.daysAndNightsShort &&
                    item.daysAndNightsShort[state?.lang as lang]) ||
                  ""
                }
                price={
                  `${FROM} ${
                    item?.priceCategoryFrom &&
                    formatMoney(
                      item.priceCategoryFrom,
                      item.priceCategoryCurrency
                    )
                  }` || ""
                }
                cardSize={"SMALL"}
              />
            ))}
          </Carousel>
        ) : (
          <></>
        )}
      </PageSection>

      {/* <PageSection
        readMoreLink={`/${state?.lang}/country/${pageContext?.slug}/${gCPT({ view: CurrentView["trips-previous"], lang: state?.lang as lang })}#${pageHash?.Inspirationen?.[state?.lang as lang]!}`}
        sanityContext={page?.previousTripIntroduction && page?.previousTripIntroduction[state?.lang as lang]}
        id={pageHash?.Inspirationen?.[state?.lang as lang]!} idTitle={pageID.Inspirationen[state?.lang as lang]!} title={state?.countryName!} >
        {page?.previousTrips?.length ? <Carousel initClass={'Inspirationen'} perView={getCarouselView(page?.previousTrips?.length)} carouselLength={page?.previousTrips?.length}>
            {page?.previousTrips?.map((item, i: number) => <Card
              uniqueRef={item?.['_id']!}
              key={i}
              _className="card-responsive glide__slide"
              imageAlt={(item.tripImage?.alt && item?.tripImage?.alt[state?.lang as lang]) || ''}
              imageUrl={buildImageFocalPoint(item.tripImage?.image)}
              title={(item?.title && item?.title[state?.lang as lang]) || ''}
              ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
              cardType="PACKAGES"
              ctaUrl={`/${state?.lang}/${item?.parentCountry?.slug &&
                item?.parentCountry?.slug[state?.lang as lang]?.current}/${_tripTranslation.slug[state?.lang as lang]}/${item?.slug
                  && item?.slug[state?.lang as lang]?.current}#${pageHash?.Inspirationen?.[state?.lang as lang]!}`}
              bodyText={(item?.cardTextShort && item.cardTextShort[state?.lang as lang]) || ''}
              duration={(item?.daysAndNightsShort && item.daysAndNightsShort[state?.lang as lang]) || ''}
              price={`${FROM} ${(item?.priceCategoryFrom && formatMoney(item.priceCategoryFrom, item.priceCategoryCurrency))}` || ''}
              cardSize={'SMALL'}
            /> )}
        </Carousel>: <></>}
      </PageSection> */}

      <PageSection
        readMoreLink={`/${state?.lang}/country/${pageContext?.slug}/${gCPT({
          view: CurrentView.accommodation,
          lang: state?.lang as lang,
        })}#${pageHash?.Unterkunfte?.[state?.lang as lang]!}`}
        sanityContext={
          page?.accomodationIntroduction &&
          page?.accomodationIntroduction[state?.lang as lang]
        }
        id={pageHash?.Unterkunfte?.[state?.lang as lang]!}
        idTitle={pageID.Unterkunfte[state?.lang as lang]!}
        title={state?.countryName!}
        layerBackground="bg-white-smoke"
      >
        {page?.accommodation?.length ? (
          <Carousel
            initClass={"Unterkünfte"}
            perView={getCarouselView(page?.accommodation?.length)}
            carouselLength={page?.accommodation?.length}
          >
            {page?.accommodation?.slice(0, 8).map((item, i: number) => (
              <Card
                uniqueRef={item?.["_id"]!}
                key={i}
                _className="card-responsive glide__slide"
                imageAlt={
                  (item?.accommodationImage?.alt &&
                    item?.accommodationImage.alt[state?.lang as lang]) ||
                  ""
                }
                imageUrl={buildImageFocalPoint(item.accommodationImage?.image)}
                title={(item?.title && item?.title[state?.lang as lang]) || ""}
                ctaText={
                  buttonTransaltion.WEITERLESEN[state?.lang as lang] || ""
                }
                cardType="ACCOMODATION"
                activities={
                  (item?.cardTextShort &&
                    item.cardTextShort[state?.lang as lang]) ||
                  ""
                }
                price={
                  `${FROM} ${
                    item?.priceCategoryFrom &&
                    formatMoney(
                      item.priceCategoryFrom,
                      item.priceCategoryCurrency
                    )
                  }` || ""
                }
                showEuroIcon={false}
                ctaUrl={`/${state?.lang}/${
                  item?.parentCountry?.slug &&
                  item?.parentCountry?.slug[state?.lang as lang]?.current
                }/${_accomodationTranslation.slug[state?.lang as lang]}/${
                  item?.slug && item?.slug[state?.lang as lang]?.current
                }`}
                location={
                  (item.locationNameShort &&
                    item.locationNameShort[state?.lang as lang]) ||
                  ""
                }
                cardSize={"SMALL"}
              />
            ))}
          </Carousel>
        ) : (
          <></>
        )}
      </PageSection>

      <PageSection
        readMoreLink={`/${state?.lang}/country/${pageContext?.slug}/${gCPT({
          view: CurrentView["travel-info"],
          lang: state?.lang as lang,
        })}#${pageHash?.Reiseinfo?.[state?.lang as lang]!}`}
        sanityContext={
          page?.travelInfoIntroduction &&
          page?.travelInfoIntroduction[state?.lang as lang]
        }
        id={pageHash?.Reiseinfo?.[state?.lang as lang]!}
        idTitle={pageID.Reiseinfo[state?.lang as lang]!}
        title={state?.countryName!}
      >
        <Card
          uniqueRef=""
          _className=""
          imageAlt={
            (page?.travelInfoImage?.image?.alt &&
              page?.travelInfoImage?.image?.alt[state?.lang as lang]) ||
            ""
          }
          imageUrl={buildImageFocalPoint(page?.travelInfoImage?.image)}
          title={""}
          bodyTextPropsAlt={
            state?.siteConfig?.travelInfoCountryInfoGlobal?.[
              state?.lang as lang
            ] && state
          }
          generateBodyTextFxn={renderPortableText}
          ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ""}
          cardType="COUNTRY"
          cardSize="JUMBO"
          showTravelBag={false}
        />
      </PageSection>
    </Layout>
  );
};

export const query = graphql`
  query countryIndexPage($id: String!) {
    pageData: sanityCountry(_id: { eq: $id }) {
      id: _id
      title: _rawTitle

      subTitle: _rawSubTitle

      countryIntroduction: _rawCountryIntroduction

      heroImage: countryImage {
        _key
        alt {
          de
          _type
        }
        image: _rawImage(resolveReferences: { maxDepth: 1 })
      }
      highlightsIntroduction: _rawHighlightsIntroduction

      countryHighlights {
        _id
        regionImage {
          _key
          alt {
            de
            _key
          }
          _type
          image: _rawImage(resolveReferences: { maxDepth: 1 })
        }
        title {
          de
        }
        parentCountry {
          slug {
            de {
              current
              _type
            }
          }
        }
        slug {
          de {
            current
          }
        }
      }

      regionIntroduction: _rawRegionsIntroduction

      region: regions {
        _id
        cardTextShort {
          de
        }
        title {
          de
        }
        slug {
          de {
            current
          }
        }
        regionImage {
          alt {
            de
          }
          image: _rawImage(resolveReferences: { maxDepth: 1 })
        }
        parentCountry {
          slug {
            de {
              current
            }

          }
        }
      }

      tripIntroduction: _rawTripsIntroduction

      trips {
        _id
        cardTextShort {
          de
         
        }
        daysAndNightsShort {
          de
         
        }
        priceCategoryFrom
        priceCategoryTo
        rating
        priceCategoryCurrency
        tripImage {
          alt {
            de
           
          }
          image: _rawImage(resolveReferences: { maxDepth: 1 })
        }
        parentCountry {
          slug {
            de {
              current
            }

          }
        }
        title {
          de
         
        }
        slug {
          de {
            current
          }

        }
      }

      previousTripIntroduction: _rawPreviousTripsIntroduction

      previousTrips {
        _id
        cardTextShort {
          de
         
        }
        daysAndNightsShort {
          de
         
        }
        priceCategoryFrom
        priceCategoryCurrency
        priceCategoryTo
        rating
        slug {
          de {
            current
          }

        }
        title {
          de
         
        }
        parentCountry {
          slug {
            de {
              current
            }

          }
        }
        tripImage {
          alt {
            de
           
          }
          image: _rawImage
        }
      }

      accomodationIntroduction: _rawAccommodationIntroduction

      accommodation {
        _id
        priceCategoryFrom
        priceCategoryTo
        priceRating
        rating
        locationText {
          de: _rawDe 
        }
        locationNameShort {
          de
         
        }
        priceCategoryCurrency
        priceCategoryExpanded {
          de
         
        }
        slug {
          de {
            current
          }

        }
        title {
          de
         
        }
        accommodationImage {
          alt {
           
            de
          }
          image: _rawImage
        }
        parentCountry {
          slug {
            de {
              current
            }

          }
        }
      }

      travelInfoIntroduction: _rawTravelInfoIntroduction

      travelInfoImage: _rawTravelInfoImage(resolveReferences: { maxDepth: 2 })

      metadata: _rawCountryLandingMetadata
    }
  }
`;

export default IndexPage;
